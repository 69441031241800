import React, { useState, useRef, useEffect, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import mixpanel from 'mixpanel-browser';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

import { ReactComponent as BellIcon } from 'assets/images/icons/svgs/bell.svg';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import useFeedNotificationStore from 'stores/FeedNotificationsStore';
import ActivityItem from './ActivityItem';
import useMobile from 'hooks/useMobile';
import { EntityActivitySelector } from './components';

const ActivityList = ({ notifications, loading, setIsOpen, uiStore }) => {
  if (loading) {
    return <CauzeSpinner />;
  }

  if (notifications.length === 0) {
    return <div className="text-center font-agenda">No activity found.</div>;
  }

  return notifications.map((notification, index) => (
    <ActivityItem
      key={notification.id}
      item={notification}
      index={index}
      onToggleOpen={() => setIsOpen(false)}
      uiStore={uiStore}
    />
  ));
};

const ActivityDropDown = ({
  profileStore,
  uiStore,
  className,
  activeEntity,
  id,
}) => {
  const feedNotificationsStore = useFeedNotificationStore();
  const hasUnread = feedNotificationsStore.getUnreadCount() > 0;
  const { isTablet } = useMobile();

  const [isOpen, setIsOpen] = useState(false);
  const [activeKey, setActiveKey] = useState(
    feedNotificationsStore.getEntityKey(activeEntity),
  );

  const notifications = feedNotificationsStore.notifications[activeKey] || [];
  const loading = feedNotificationsStore.loading[activeKey] || false;

  useKeyboardEvent('Escape', () => {
    setIsOpen(false);
  });

  let triggerRef = useRef(null);

  useEffect(() => {
    if (!isOpen && triggerRef.current) {
      triggerRef.current.blur();
    }
  }, [isOpen]);

  useEffect(() => {
    if (profileStore.availableUserEntities) {
      feedNotificationsStore.getAllNotifications(
        profileStore.availableUserEntities,
      );

      const interval = setInterval(() => {
        feedNotificationsStore.pollNotifications(
          profileStore.availableUserEntities,
        );
      }, 60000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [profileStore.availableUserEntities]);

  useEffect(() => {
    setActiveKey(feedNotificationsStore.getEntityKey(activeEntity));
  }, [activeEntity]);

  const getActiveEntityFromKey = useCallback(() => {
    const activeEntity = profileStore.availableUserEntities.find(
      (entity) => feedNotificationsStore.getEntityKey(entity) === activeKey,
    );
    return activeEntity;
  }, [profileStore.availableUserEntities, activeKey]);

  if (!profileStore.hasFetchedInitial) {
    return null;
  }

  const _setIsOpen = (open) => {
    mixpanel.track(`Activity Drop Down ${open ? 'opened' : 'closed'}`);
    setIsOpen(open);
  };

  return (
    <div
      id={id}
      className={cx(
        'dropdown',
        'activity-dropdown',
        'is-right',
        { 'is-active': isOpen },
        className,
      )}
    >
      <div
        className={cx(
          'flex h-8 w-8 lg:h-10 lg:w-10 justify-center items-center rounded-lg relative my-auto',
          {
            'bg-lightgray-b2b bg-opacity-50': isOpen,
          },
        )}
      >
        {hasUnread && (
          <div className="w-2.5 h-2.5 top-[2px] right-1 lg:top-1 lg:right-2 absolute rounded-full bg-cauze-dark" />
        )}
        <button
          ref={triggerRef}
          onClick={() => _setIsOpen(!isOpen)}
          className={
            'bg-transparent border-none cursor-pointer text-white !p-0'
          }
          aria-haspopup="true"
          aria-controls="activity-dropdown-menu"
        >
          <BellIcon />
        </button>
      </div>
      <div
        className={cx('dropdown-menu activity-dropdown-menu', {
          '!left-0 !right-0 !top-[60px] !fixed !pt-0 w-screen': isTablet,
        })}
        id="activity-dropdown-menu"
        role="menu"
      >
        <div
          className={cx(
            'backdrop !bg-transparent h-screen w-screen !pt-[60px] lg:!pt-[76px] !mt-0',
          )}
          onClick={() => _setIsOpen(false)}
        >
          <div className="backdrop !mt-[60px] lg:!mt-[76px]" />
        </div>
        <div className="dropdown-content !rounded-none lg:!rounded-[10px] overflow-hidden !px-0 !py-0 !pb-0 w-screen lg:w-[375px]">
          <div className="py-5 flex flex-col gap-y-2.5 h-full">
            <div className="flex h-[34px] px-5 justify-between">
              <h5 className="font-agenda-bold leading-[34px] h-full text-[17px] text-gray-1b2">
                Activity
              </h5>
              <button
                className={cx(
                  'bg-transparent border-none cursor-pointer disabled:cursor-auto font-agenda-semibold text-cauze-primary text-[13px]',
                  {
                    'text-lightgray-999': !Boolean(
                      feedNotificationsStore.unreadCount[activeKey],
                    ),
                  },
                )}
                disabled={
                  !Boolean(feedNotificationsStore.unreadCount[activeKey])
                }
                onClick={() => {
                  feedNotificationsStore.markAllRead(getActiveEntityFromKey());
                }}
              >
                MARK AS READ
              </button>
            </div>
            <div>
              <EntityActivitySelector
                entities={profileStore.availableUserEntities}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
              />
            </div>
            <div className="activity-list-container flex-1 overflow-y-auto">
              <ActivityList
                notifications={notifications}
                loading={loading}
                uiStore={uiStore}
                setIsOpen={setIsOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'authStore',
  'uiStore',
  'profileStore',
  'eventStore',
)(observer(ActivityDropDown));
