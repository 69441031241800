import React, { useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import cx from 'classnames';

import CharityDetailsModal from 'components/modals/CharityDetailsModal';
import LoginModal from 'components/modals/LoginModal/LoginModal';
import useSearch from 'hooks/useSearch';
import withUserContext from 'behaviors/withUserContext';
import { EventListWithArrowsAbove } from 'components/EventList/EventList';
import useDonationLanding from 'hooks/useDonationLanding';
import useProfileById from 'hooks/useProfileById';
import TopicList from 'components/TopicList/TopicList';
import useLocalFeedStore from 'stores/LocalFeedStore';

import { PopularNonprofitsSection } from './components';
import GlobalFeed from 'components/Feed/GlobalFeed';
import useDashboardStore from 'stores/DashboardStore';
import useMobile from 'hooks/useMobile';

const DiscoverView = ({
  activeEntity,
  searchStore,
  uiStore,
  donationLandingStore,
  userProfileStore,
}) => {
  const { isTablet } = useMobile();
  useDonationLanding();

  const userId =
    activeEntity?.userContext?.userId ||
    activeEntity?.userContext?.influencerId;

  useProfileById({
    id: userId,
    userContext: activeEntity?.userContext,
  });
  useSearch();

  const user = userProfileStore.users.get(+userId);
  const {
    getLocalNonprofits,
    getParsedLocation,
    localEntities,
    parsedLocationString,
  } = useLocalFeedStore();
  const { setProps } = useDashboardStore();

  const [detailCharity, setDetailCharity] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(true);
  const [zipCode, setZipCode] = useState(user?.zip);

  const topics = useMemo(() => {
    searchStore.topics.concat(searchStore.categories);

    const _topics = toJS(searchStore.topics);
    const _categories = toJS(searchStore.categories);

    return [..._topics, ..._categories];
  }, [searchStore.topics, searchStore.categories]);

  useEffect(() => {
    setLocalLoading(true);
    Promise.all(
      zipCode
        ? [
            getParsedLocation({ location: zipCode }),
            getLocalNonprofits({ location: zipCode }),
          ]
        : [],
    ).finally(() => {
      setLocalLoading(false);
    });
  }, [zipCode, getLocalNonprofits]);

  useEffect(() => {
    setZipCode(user?.zip);
  }, [user]);

  useEffect(() => {
    setProps({
      size: 'half',
      title: 'Discover',
    });
  }, []);

  return (
    <div className="search-view flex-column">
      <div className="mb-5">
        {showDetails && (
          <CharityDetailsModal
            isOpen={showDetails}
            mini
            onToggleClose={() => {
              setShowDetails(false);
              setDetailCharity(null);
            }}
            charity={detailCharity}
          />
        )}
        <LoginModal
          isOpen={showLoginModal}
          onToggleClose={() => setShowLoginModal(false)}
        />

        <TopicList
          title="Trending Topics"
          topics={topics}
          loading={searchStore.loading}
        />
        <EventListWithArrowsAbove
          title="Join Popular Cauzes"
          events={donationLandingStore.topCauzes}
          loading={donationLandingStore.loadingCauzes}
          uiStore={uiStore}
          className="mt-8"
        />
        <PopularNonprofitsSection
          user={user}
          localEntities={localEntities}
          activeEntity={activeEntity}
          setShowLoginModal={setShowLoginModal}
          localLoading={localLoading}
          userLoading={userProfileStore.loading}
          parsedLocationString={parsedLocationString}
          onApply={(newZip) => {
            setZipCode(newZip);
            userProfileStore.updateZipFromParsedLocation({
              location: newZip,
              id: user?.id,
            });
          }}
        />
      </div>
      <GlobalFeed
        activeEntity={activeEntity}
        setShowLoginModal={setShowLoginModal}
        title="Global Feed"
      />
    </div>
  );
};

export default withUserContext(
  inject(
    'searchStore',
    'donationLandingStore',
    'uiStore',
    'authStore',
    'charityStore',
    'localFeedStore',
    'userProfileStore',
    'profileStore',
  )(observer(DiscoverView)),
);
