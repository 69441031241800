import { toJS } from 'mobx';
import { create } from 'zustand';

import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import { likeMutation, unlikeMutation, followingFeedQuery } from 'graphql/feed';
import { likeActorsForPurchaseQuery } from 'graphql/cauzePurchase';
import { followMutation, unfollowMutation } from 'graphql/follow';

const initialState = {
  isError: false,
  feedLoading: false,
  feedLoadingMore: false,
  hasFetchedInitial: false,
  feed: [],
  likeActors: [],
  lastFetchTime: null,
  itemsAmountAlert: 0,
  offset: 0,
  limit: 30,
  hasMoreItems: true,
};

const useFollowingFeedStore = create((set, get) => ({
  ...initialState,
  resetItemAmount: () => {
    set({ itemAmountAlert: 0 });
  },
  clearFeed: async () => {
    set(initialState);
  },
  getFeed: async () => {
    const state = get();
    if (state.feedLoading || state.feedLoadingMore) {
      return;
    }

    set({
      feedLoading: true,
      offset: 0,
      lastFetchTime: new Date().toISOString(),
    });

    try {
      const result = await api2Client.query({
        variables: { limit: state.limit, offset: state.offset },
        fetchPolicy: 'no-cache',
        query: followingFeedQuery,
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      });

      set({
        feed: result.data.followingFeed,
        hasFetchedInitial: true,
        offset: state.offset + result.data.followingFeed.length,
        hasMoreItems: result.data.followingFeed.length === state.limit,
      });
    } catch (err) {
      console.log(err);
    }

    set({ feedLoading: false });

    return state.feed;
  },
  fetchNew: async () => {
    const state = get();
    if (!state.hasMoreItems || state.feedLoadingMore || state.feedLoading) {
      return;
    }

    set({ feedLoading: true });

    try {
      const result = await api2Client.query({
        variables: {
          limit: state.limit,
          offset: state.offset,
          sinceTime: state.lastFetchTime,
        },
        fetchPolicy: 'no-cache',
        query: followingFeedQuery,
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      });
      const feed = result.data.followingFeed;

      set({
        lastFetchTime: new Date().toISOString(),
        feedLoading: false,
        itemAmountAlert: result.length,
      });

      if (
        feed.length > 0 &&
        state.feed[0].eventId === (result.data.followingFeed || [])[0].eventId
      ) {
        state.feed.remove(this.feed[0]);
      }

      const newFeed = [...feed];
      newFeed.unshift(...feed);

      set({
        feed: newFeed,
        offset: state.offset + result.data.followingFeed.length,
        hasMoreItems: result.data.followingFeed.length === state.limit,
      });
    } catch (err) {
      console.log(err);
    }
  },
  loadMore: async () => {
    const state = get();

    if (!state.hasMoreItems || state.feedLoadingMore || state.feedLoading) {
      return;
    }

    set({ feedLoadingMore: true });

    try {
      const result = await api2Client.query({
        variables: {
          limit: state.limit,
          offset: state.offset,
        },
        fetchPolicy: 'no-cache',
        query: followingFeedQuery,
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      });

      set({
        feed: state.feed.concat(result.data.followingFeed),
        offset: state.offset + result.data.followingFeed.length,
        hasMoreItems: result.data.followingFeed.length === state.limit,
      });
    } catch (err) {
      console.log(err);
    }

    set({ feedLoadingMore: false });

    return state.feed;
  },
  like: ({ like = true, purchaseId, index, userContext }) => {
    const state = get();
    const newFeed = [...state.feed];
    console.log(state.feed, 'feed', newFeed, newFeed[index], index);

    newFeed[index].currentEntityLiked = like;
    newFeed[index].likeCount = like
      ? newFeed[index].likeCount + 1
      : newFeed[index].likeCount - 1;

    const options = {
      variables: { id: purchaseId, userContext },
      mutation: like ? likeMutation : unlikeMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      newFeed[index].currentEntityLiked = !like;
      newFeed[index].likeCount = like
        ? newFeed[index].likeCount - 1
        : newFeed[index].likeCount + 1;
      if (global.IS_DEV) {
        console.log(err);
      }
    });

    set({
      feed: toJS(state.feed),
    });
  },
  getLikeActors: async ({ purchaseId, userContext }) => {
    try {
      const options = {
        variables: { purchaseId, userContext },
        query: likeActorsForPurchaseQuery,
        fetchPolicy: 'network-only',
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };

      const queryResult = await apolloClient.query(options);
      set({ likeActors: queryResult.data.likeActorsForPurchase });
    } catch (err) {
      console.log(err);
    }
  },
  follow: ({ follow = true, targetContext, actorContext }) => {
    const state = get();

    const likeActors = [...state.likeActors];

    likeActors.forEach((likeActor, index) => {
      if (
        (likeActor.entityType === 'USER' &&
          likeActor.id === targetContext.userId) ||
        (likeActor.entityType === 'CHARITY' &&
          likeActor.id === targetContext.charityId) ||
        (likeActor.entityType === 'COMPANY' &&
          likeActor.id === targetContext.companyId)
      ) {
        likeActors[index].isSelfFollowing = follow;
      }
    });

    const options = {
      variables: {
        targetContext,
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      likeActors.forEach((likeActor, index) => {
        if (
          (likeActor.entityType === 'USER' &&
            likeActor.id === targetContext.userId) ||
          (likeActor.entityType === 'CHARITY' &&
            likeActor.id === targetContext.charityId) ||
          (likeActor.entityType === 'COMPANY' &&
            likeActor.id === targetContext.companyId)
        ) {
          likeActors[index].isSelfFollowing = !follow;
        }
      });
      if (global.IS_DEV) {
        console.log(err);
      }
    });

    set({ likeActors });
  },
  clearLikeActors: () => {
    set({ likeActors: [] });
  },
  updateFeedItems: async ({ updatedItems }) => {
    const state = get();
    let feed = [...state.feed];

    if (updatedItems) {
      updatedItems.forEach((updatedItem) => {
        const matchedIndex = feed.findIndex(
          (item) => item.purchaseId === updatedItem.purchaseId,
        );
        if (matchedIndex >= 0) {
          feed[matchedIndex] = updatedItem;
        }
      });
    }
    feed = toJS(feed);

    set({ feed });
  },
  onUnmount: () => {
    const state = get();
    state.clearFeed();
  },
  updateFeedItemOptimistically: ({ index, updatedProps }) => {
    const state = get();
    if (!state.feed) {
      return;
    }

    const feed = state.feed;

    feed[index] = {
      ...toJS(feed[index]),
      ...updatedProps,
      ...(updatedProps.commentCount
        ? {
            commentCount: updatedProps.commentCount,
          }
        : {}),
      ...(updatedProps.likesCount
        ? {
            likesCount: feed[index].likeCount + updatedProps.likeCount,
          }
        : {}),
    };
    set({ feed: toJS(feed) });
  },
}));

export default useFollowingFeedStore;
