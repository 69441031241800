import gql from 'graphql-tag';

const parseLocationQuery = gql`
  query parseLocation($location: String) {
    parseLocation(location: $location) {
      city
      stateCode
      zipcode
    }
  }
`;

const feedQuery = gql`
  query feedQuery(
    $id: ID
    $page: Int!
    $page_size: Int!
    $purchase_id: ID
    $type: FeedType!
    $user_context: UserContext
    $sort_order: FeedSortType
  ) {
    feed(
      id: $id
      page: $page
      pageSize: $page_size
      purchaseId: $purchase_id
      type: $type
      userContext: $user_context
      sortOrder: $sort_order
    ) {
      pageNumber
      pageSize
      total
      totalPages
      items {
        actor {
          id
          isSelf
          avatar {
            sm
            md
          }
          name
          body
          previewUrl
          image {
            md
            lg
            xl
            full
            __typename
          }
          entityType
          unclaimed
          isActive
        }
        initiator {
          id
          avatar {
            sm
            md
          }
          name
          entityType
        }
        matchActors {
          id
          avatar {
            sm
            md
          }
          name
          entityType
        }
        id
        eventId
        eventType
        purchaseId
        hasLiked
        shareLink
        donationAmount
        commentsCount
        hasCommented
        likes
        date
        subjects {
          id
          name
          subjectType
          avatar {
            sm
            md
            __typename
          }
        }
        type
        purchaseCount
        hasJoined
      }
    }
  }
`;

const lightweightFeedQuery = gql`
  query feedQuery(
    $id: ID
    $page: Int!
    $page_size: Int!
    $type: FeedType!
    $user_context: UserContext
  ) {
    feed(
      id: $id
      page: $page
      pageSize: $page_size
      type: $type
      userContext: $user_context
    ) {
      pageNumber
      pageSize
      total
      totalPages
      items {
        actor {
          id
          isSelf
          purchaseId
          avatar {
            sm
            md
          }
          name
          body
          previewUrl
          image {
            md
            lg
            xl
            full
            __typename
          }
          entityType
          isActive
          unclaimed
        }
        initiator {
          id
          avatar {
            sm
            md
          }
          name
          entityType
        }
        matchActors {
          id
          avatar {
            sm
            md
          }
          name
          entityType
        }
        id
        eventId
        eventDescription
        eventType
        purchaseId
        date
        subjects {
          id
          name
          subjectType
          avatar {
            sm
            md
            __typename
          }
        }
        type
        purchaseCount
        hasJoined
      }
    }
  }
`;

const purchaseFeedQuery = gql`
  query purchaseFeedQuery($eventId: Int!) {
    purchaseFeed(eventId: $eventId) {
      cauzePurchase {
        id
        commentBody # Body
        insertedAt # (derived) Days ago
        currentEntityLiked # Liked state
        actor {
          avatarUrls {
            md # Oval avatar image
          }
          id
          name # Investor name
        }
      }
      purchaseLikeCount # Total Likes
    }
  }
`;

const feedQueryFragment = `
  itemType
  totalActorCount
  commentCount
  likeCount
  currentEntityLiked
  currentEntityCommented
  purchaseComment
  projects {
    id
    name
  }
  activityRoot {
    activityEntity {
      id
      type
    }
  }
  joinedEntity {
    id
    name
    type
  }
  activityPurchase {
    insertedAt
    id
    itemType
    matchPurchases {
      matchSponsor {
        id
        name
        type
      }
      match {
        multiplier
        donorCount
      }
    }
  }
  activityEntity {
    id
    type
    name
    isCurrentEntity
    avatarUrls {
      md
    }
    user {
      isActive
      unclaimed
    }
  }
  event {
    id
    name
    eventType
    description
    giverCount
    purchaserCount
    current
    previewUrl
    shareLink
    image {
      lg
    }
    projects {
      id
      avatarUrls {
        md
        sm
      }
    }
    topPurchasers {
      actor {
        name
        avatarUrls {
          md
          sm
        }
      }
    }
  }
`;

const projectFeedQuery = gql`
  query projectFeedQuery($projectId: Int!, $limit: Int, $offset: Int) {
    projectFeed(projectId: $projectId, limit: $limit, offset: $offset) {
      ${feedQueryFragment}
    }
  }
`;

const userFeedQuery = gql`
  query userFeedQuery($userId: Int!, $limit: Int, $offset: Int) {
    userFeed(userId: $userId, limit: $limit, offset: $offset) {
      ${feedQueryFragment}
    }
  }
`;

const companyFeedQuery = gql`
  query companyFeedQuery($companyId: Int!, $offset: Int, $limit: Int) {
    companyFeed(companyId: $companyId, offset: $offset, limit: $limit) {
      ${feedQueryFragment}
    }
  }
`;

const globalFeedQuery = gql`
  query globalFeedQuery($offset: Int, $limit: Int) {
    globalFeed(offset: $offset, limit: $limit) {
      ${feedQueryFragment}
    }
  }
`;

const eventFeedQuery = gql`
  query eventFeedQuery($eventId: Int!, $limit: Int, $offset: Int) {
    cauzeFeed(eventId: $eventId, limit: $limit, offset: $offset) {
      ${feedQueryFragment}
    }
  }
`;

const localFeedQuery = gql`
  query localFeed(
    $limit: Int!
    $offset: Int!
    $sinceTime: DateTime
    $zipcode: String
  ) {
    localFeed(
      limit: $limit
      offset: $offset
      sinceTime: $sinceTime
      zipcode: $zipcode
    ) {
      commentCount
      currentEntityCommented
      currentEntityJoined
      likeCount
      currentEntityLiked
      itemType
      purchaseComment
      totalActorCount
      topPurchasers {
        actor {
          avatarUrls {
            sm
          }
        }
      }
      currentEntityJoinedFollowers {
        id
        name
        avatarUrls {
          md
          lg
          xl
        }
      }
      totalRaised
      purchaseCommentImageUrls {
        md
        lg
        xl
      }
      activityPurchase {
        id
        matchPurchases {
          matchSponsor {
            id
            name
            type
          }
          match {
            multiplier
            donorCount
          }
        }
      }
      activityEntity {
        id
        name
        username
        isCurrentEntity
        type
        avatarUrls {
          sm
          md
        }
      }
      joinedEntity {
        id
        name
        username
        type
        isCurrentEntity
      }
      totalActorCount
      event {
        id
        name
        description
        current
        purchaserCount
        eventType
        image {
          sm
          md
          lg
          xl
        }
        hosts {
          name
          id
          isCurrentEntity
          type
          avatarUrls {
            sm
            md
            lg
          }
        }
        topPurchasers {
          actor {
            avatarUrls {
              sm
            }
            type
          }
        }
        matches {
          active
          currentEntityRemaining
          totalRemaining
          multiplier
          matchType
        }
      }
      projects {
        id
        name
        avatarUrls {
          sm
          md
        }
      }
      activityRoot {
        commentCount
        likeCount
        itemType
        purchaseComment
        totalActorCount
        purchaseCommentImageUrls {
          md
          lg
          xl
        }
        activityEntity {
          id
          name
          username
          isCurrentEntity
          type
          user {
            unclaimed
            isActive
          }
          avatarUrls {
            sm
            md
          }
        }
        joinedEntity {
          name
          isCurrentEntity
        }
        activityPurchase {
          id
          matchPurchases {
            matchSponsor {
              id
              name
              type
            }
            match {
              multiplier
              donorCount
            }
          }
        }
        event {
          id
          name
          description
          current
          purchaserCount
          image {
            sm
            md
            lg
            xl
          }
          hosts {
            name
            id
            isCurrentEntity
            type
            avatarUrls {
              sm
              md
              lg
            }
          }
          topPurchasers {
            actor {
              avatarUrls {
                sm
              }
              type
            }
          }
        }
        projects {
          id
          name
          avatarUrls {
            sm
            md
          }
        }
      }
    }
  }
`;

const followingFeedQuery = gql`
  query followingFeed($limit: Int!, $offset: Int!, $sinceTime: DateTime) {
    followingFeed(limit: $limit, offset: $offset, sinceTime: $sinceTime) {
      commentCount
      currentEntityCommented
      currentEntityJoined
      likeCount
      currentEntityLiked
      itemType
      purchaseComment
      totalActorCount
      topPurchasers {
        actor {
          avatarUrls {
            sm
          }
        }
      }
      currentEntityJoinedFollowers {
        id
        name
        avatarUrls {
          md
          lg
          xl
        }
      }
      totalRaised
      purchaseCommentImageUrls {
        md
        lg
        xl
      }
      activityPurchase {
        id
        matchPurchases {
          matchSponsor {
            id
            name
            type
          }
          match {
            multiplier
            donorCount
          }
        }
      }
      activityEntity {
        id
        name
        username
        isCurrentEntity
        type
        user {
          isActive
          unclaimed
        }
        avatarUrls {
          sm
          md
        }
      }
      joinedEntity {
        id
        name
        username
        type
        isCurrentEntity
      }
      totalActorCount
      event {
        id
        name
        description
        current
        purchaserCount
        previewUrl
        eventType
        image {
          sm
          md
          lg
          xl
        }
        hosts {
          name
          id
          isCurrentEntity
          type
          avatarUrls {
            sm
            md
            lg
          }
        }
        topPurchasers {
          actor {
            avatarUrls {
              sm
            }
            type
          }
        }
        matches {
          active
          currentEntityRemaining
          totalRemaining
          multiplier
          matchType
        }
      }
      projects {
        id
        name
        avatarUrls {
          sm
          md
        }
      }
      activityRoot {
        commentCount
        likeCount
        itemType
        purchaseComment
        totalActorCount
        purchaseCommentImageUrls {
          md
          lg
          xl
        }
        activityEntity {
          id
          name
          username
          isCurrentEntity
          type
          user {
            isActive
            unclaimed
          }
          avatarUrls {
            sm
            md
          }
        }
        joinedEntity {
          name
          isCurrentEntity
        }
        activityPurchase {
          id
          matchPurchases {
            matchSponsor {
              id
              name
              type
            }
            match {
              multiplier
              donorCount
            }
          }
        }
        event {
          id
          name
          description
          current
          purchaserCount
          image {
            sm
            md
            lg
            xl
          }
          hosts {
            name
            id
            isCurrentEntity
            type
            avatarUrls {
              sm
              md
              lg
            }
          }
          topPurchasers {
            actor {
              avatarUrls {
                sm
              }
              type
            }
          }
        }
        projects {
          id
          name
          avatarUrls {
            sm
            md
          }
        }
      }
    }
  }
`;

const likeMutation = gql`
  mutation likePurchaseItem($id: ID!, $user_context: UserContext) {
    like(purchaseId: $id, userContext: $user_context) {
      purchaseId
      likes
    }
  }
`;

const unlikeMutation = gql`
  mutation unlikePurchaseItem($id: ID!, $user_context: UserContext) {
    unlike(purchaseId: $id, userContext: $user_context) {
      purchaseId
      likes
    }
  }
`;
const activityFeedItemQuery = gql`
  query activityFeedItem($purchaseId: Int!) {
    activityFeedItem(purchaseId: $purchaseId) {
      activityPurchase {
        id
      }
      activityEntity {
        id
        name
        isCurrentEntity
        avatarUrls {
          md
          lg
          xl
        }
      }
      event {
        id
      }
      currentEntityJoinedFollowers {
        id
        name
        isCurrentEntity
        avatarUrls {
          md
          lg
          xl
        }
      }
    }
  }
`;

const createCommentMutation = gql`
  mutation createCommentMutation(
    $body: String
    $purchaseId: Int
    $imageId: Int
    $previewUrl: String
  ) {
    createComment(
      body: $body
      purchaseId: $purchaseId
      imageId: $imageId
      previewUrl: $previewUrl
    ) {
      id
    }
  }
`;

const editCommentMutation = gql`
  mutation editCommentMutation(
    $commentId: Int!
    $body: String
    $imageId: Int
    $previewUrl: String
  ) {
    editComment(
      commentId: $commentId
      body: $body
      imageId: $imageId
      previewUrl: $previewUrl
    ) {
      id
    }
  }
`;
const deleteCommentMutation = gql`
  mutation deleteCommentMutation($commentId: Int!) {
    deleteComment(commentId: $commentId) {
      id
    }
  }
`;

export {
  feedQuery,
  lightweightFeedQuery,
  purchaseFeedQuery,
  projectFeedQuery,
  userFeedQuery,
  companyFeedQuery,
  globalFeedQuery,
  eventFeedQuery,
  parseLocationQuery,
  localFeedQuery,
  followingFeedQuery,
  likeMutation,
  unlikeMutation,
  activityFeedItemQuery,
  createCommentMutation,
  editCommentMutation,
  deleteCommentMutation,
};
