import { runInAction } from 'mobx';
import { create } from 'zustand';
import mixpanel from 'mixpanel-browser';

import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';

import uiStore from './UiStore';

import {
  paymentOptionsQueryV2,
  disassociatePaymentMethodMutation,
  addCardMutation,
  verifyBankAccountMutation,
  setAutopayMutation,
  addBankAccountMutation,
  createSetupIntentMutation,
  associatePaymentMethodMutation,
  updateDefaultPaymentMethodMutation,
} from 'graphql/paymentOptions';
import { plaidLinkTokenQuery } from 'graphql/giftCheckout';

const initialState = {
  loading: true,
  isError: false,
  loadingPaymentMethods: true,
  balance: 0,
  cards: [],
  recurringDepositDay: undefined,
  recurringAmount: undefined,
  recurringCardId: undefined,
  hasAutopayEnabled: false,
  userContext: {},
  paymentOptions: [],
  stripe: undefined,
};

const useWalletStore = create((set, get) => ({
  ...initialState,
  setStripeObject: (stripe) => set({ stripe }),
  getInitial: ({ userContext }) => {
    const state = get();
    set({ userContext });
    state.getPaymentMethods({ userContext });
  },
  plaidValidate: () => {
    return true;
  },
  getPaymentMethods: async ({ userContext = {} }) => {
    const options = {
      variables: { includePending: true },
      query: paymentOptionsQueryV2,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);

      set({ loadingPaymentMethods: false });

      runInAction(() => {
        if (
          result.data.paymentOptions &&
          result.data.paymentOptions.length > 0
        ) {
          set({ paymentOptions: result.data.paymentOptions });

          if (result.data.paymentOptions[0].paymentType !== 'balance') {
            set({ balance: 0 });
          }

          let cards = [];
          result.data.paymentOptions.forEach((paymentOption) => {
            if (paymentOption.paymentType === 'balance') {
              set({
                balance: paymentOption.balance.total,
                recurringCardId: paymentOption.balance.recurringPaymentMethodId,
                recurringDepositDay: paymentOption.balance.recurringDepositDay,
                recurringAmount: paymentOption.balance.recurringAmount,
              });
            }
            if (
              paymentOption.paymentType === 'stripe_cc' ||
              paymentOption.paymentType === 'stripe_ach'
            ) {
              cards = cards.concat(paymentOption.paymentMethods || []);

              if (userContext.companyId) {
                set({
                  hasAutopayEnabled:
                    paymentOption.paymentMethods.filter(
                      (card) => card.autopayEnabled,
                    ).length > 0,
                });
              }
            }
          });
          set({ cards });
        }
      });

      return get().paymentOptions;
    } catch (err) {
      set({ loadingPaymentMethods: false });
    }

    return [];
  },
  registerNewCard: async ({ userContext = {}, elements, stripe }) => {
    const state = get();

    try {
      await elements.submit();

      // get payment setup client secret
      let options = {
        variables: { paymentMethodTypes: ['CARD'] },
        query: createSetupIntentMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };
      let result = await api2Client.query(options);
      const { clientSecret } = result.data.createSetupIntent;

      const _stripe = stripe || state.stripe;

      const cardResult = await _stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.origin}/donate`,
          payment_method_data: {
            billing_details: {
              name: '',
              email: '',
              phone: '',
              address: {
                city: '',
                country: 'US',
                state: '',
                postal_code: '',
                line1: '',
                line2: '',
              },
            },
          },
        },
      });

      if (cardResult.error) {
        uiStore.showNotification({
          body: 'Could not add your card. Please try again.',
          type: 'ERROR',
        });
        return false;
      } else {
        let options = {
          variables: { setupIntentId: cardResult.setupIntent.id },
          query: associatePaymentMethodMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        await api2Client.query(options);
        await state.getPaymentMethods({ userContext });
        return cardResult;
      }
    } catch (err) {
      uiStore.showNotification({
        body: 'Could not add your card. Please try again.',
        type: 'ERROR',
      });
    }

    return false;
  },
  removeCard: async ({ cardId, userContext }) => {
    const state = get();
    const options = {
      variables: {
        cardId,
      },
      mutation: disassociatePaymentMethodMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      await api2Client.mutate(options);
      state.getPaymentMethods({ userContext });
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  },
  addBankAccount: async ({ publicToken, metadata, nickname, userContext }) => {
    const state = get();
    const options = {
      variables: {
        plaidToken: publicToken,
        accountId: metadata.accounts[0]?.id,
        nickname,
        userContext: userContext,
      },
      mutation: addBankAccountMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    const res = await apolloClient.mutate(options);
    state.getPaymentMethods({ userContext: userContext });
    return res;
  },
  addAch: async ({ achData, userContext }) => {
    const state = get();
    try {
      const res = await state.stripe.createToken('bank_account', achData);
      if (res.errors) {
        res.errors.forEach((err) => {
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          });
        });
        return false;
      }
      if (res.token) {
        const options = {
          variables: {
            stripeToken: res.token.id,
            userContext: userContext,
          },
          mutation: addCardMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        await apolloClient.mutate(options);
        await state.getPaymentMethods({
          userContext,
        });
        uiStore.showNotification({
          body: 'Bank account added, verify here once transactions appear on statement.',
          type: 'SUCCESS',
        });
        return true;
      } else {
        throw new Error('No Token');
      }
    } catch (err) {
      uiStore.showNotification({
        body: 'Could not add your bank account. Please try again.',
        type: 'ERROR',
      });
    }
  },
  verifyAch: async ({ cardId, amount1, amount2, userContext }) => {
    const state = get();
    try {
      const options = {
        variables: {
          cardId,
          amount1: parseInt(amount1),
          amount2: parseInt(amount2),
          userContext,
        },
        mutation: verifyBankAccountMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      };
      const res = await apolloClient.mutate(options);

      if (res.errors) {
        res.errors.forEach((err) => {
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          });
        });
        return false;
      }
      await state.getPaymentMethods({
        userContext,
      });
      return true;
    } catch (err) {
      console.log(err);
    }
  },
  generatePlaidLinkToken: async () => {
    const options = {
      query: plaidLinkTokenQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const plaidLinkToken = await apolloClient
        .query(options)
        .then((res) => res.data.plaidLinkToken);
      set({ plaidLinkToken });
    } catch (err) {
      console.log(err);
    }
  },
  onPlaidStart: () => {
    mixpanel.track('Donation Checkout Started with Plaid');
  },
  onPlaidSuccess: async (token, metadata) => {
    const state = get();
    set({ checkoutLoading: true });
    try {
      await state.addBankAccount({
        publicToken: token,
        metadata: metadata,
        userContext: state.userContext,
      });
    } catch (error) {
      set({ checkoutLoading: false });
      state.onPlaidError(error);
    }
  },
  onPlaidError: (error, _metadata) => {
    mixpanel.track('Donation Checkout Error with Plaid');
    set({ checkoutLoading: false });
    console.log(error);
  },
  onPlaidExitEvent: (_eventName, _metadata) => {
    mixpanel.track('Donation Checkout User Closed Plaid Modal');
    set({ checkoutLoading: false });
  },
  setAutopayEnabled: async ({ autopayEnabled, cardId, userContext }) => {
    const state = get();

    try {
      const options = {
        variables: {
          autopayEnabled,
          cardId,
          userContext,
        },
        mutation: setAutopayMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      };
      const res = await apolloClient.mutate(options);

      if (res.errors) {
        res.errors.forEach((err) => {
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          });
        });

        return false;
      }

      state.getPaymentMethods({ userContext }).then((_res) => {
        uiStore.showNotification({
          body: `ACH autopay is now ${autopayEnabled ? 'enabled' : 'disabled'}`,
          type: 'SUCCESS',
        });
        set({ hasAutopayEnabled: autopayEnabled });
      });
    } catch (err) {
      uiStore.showNotification({
        body: 'There was an issue connecting your ACH account for autopay. Support has been notified.',
        type: 'ERROR',
      });
    }
  },
  updateDefaultCard: async ({ cardId, userContext }) => {
    const state = get();
    const options = {
      variables: {
        cardId,
      },
      mutation: updateDefaultPaymentMethodMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const cards = state.cards.map((card) => ({
        ...card,
        isDefault: card.id === cardId,
      }));

      set({ cards });

      await api2Client.mutate(options);
      state.getPaymentMethods({ userContext });
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  },
  onUnmount: () => {
    set({
      isError: false,
      balance: 0,
      cards: [],
    });
  },
}));

export default useWalletStore;
