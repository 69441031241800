import React, { Component } from 'react';
import { debounce } from 'lodash';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { navigate } from '@reach/router';

import colors from 'styles/colors';

import Avatar from 'components/Avatar/Avatar';
import { ReactComponent as SearchIcon } from 'assets/images/icons/search/search.svg';
import CountrySelect from 'components/SelectCountry/SelectCountry';
import { GlobalCharityAsyncSelectStyles } from './GlobalCharityAsyncSelectStyles';
import classNames from 'classnames';

const countryWhitelist = [
  { name: 'United States', value: 'United States', icon: 'us' },
  { name: 'Canada', value: 'Canada', icon: 'canada' },
  { name: 'Global', value: 'Global', icon: 'global' },
];
const getSeeAllLabel = (inputValue, location) => {
  if (inputValue && location) {
    return `See all results for "${inputValue}" in "${location}"`;
  } else if (inputValue) {
    return `See all results for "${inputValue}"`;
  } else if (location) {
    return `See all results in "${location}"`;
  }
  return 'See all results';
};

const animatedComponents = makeAnimated();

const MultiValueContainer = (props) => (
  <components.MultiValueContainer {...props}>
    <div className="flex-row">
      {props.data.avatar && (
        <div
          style={{
            justifySelf: 'stretch',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            xsm
            style={{ marginLeft: '0.25rem' }}
            avatar={props.data.avatar}
          />
          {props.data}
        </div>
      )}
      {props.children}
    </div>
  </components.MultiValueContainer>
);

const Menu = (props) => {
  const inputValue = props.selectProps.inputValue;
  const location = props.location;

  return (
    <>
      <components.Menu {...props}>
        {props.children}
        {Boolean(props.options.length) && (
          <div className="flex justify-center w-full bg-lightgray-f1f">
            <div
              className="w-full font-agenda text-gray-1b2 text-center py-4 !h-12 hover:cursor-pointer"
              onClick={(event) => {
                event.preventDefault();

                props.selectProps.onMenuClose();
                navigate('/donate/search', { state: { query: inputValue } });
              }}
            >
              {inputValue && location ? (
                <>
                  See all results for{' '}
                  <span className="font-agenda-bold">"{inputValue}"</span> in
                  City/Zip Code{' '}
                  <span className="font-agenda-bold">"{location}"</span>
                </>
              ) : inputValue ? (
                <>
                  See all results for{' '}
                  <span className="font-agenda-bold">"{inputValue}"</span>
                </>
              ) : location ? (
                <>
                  See all results for City/Zip Code{' '}
                  <span className="font-agenda-bold">"{location}"</span>
                </>
              ) : (
                'See all results'
              )}
            </div>
          </div>
        )}
      </components.Menu>
    </>
  );
};

const Option = (props) => {
  console.log('props', props);
  return (
    <components.Option {...props}>
      <div className="flex-row flex-align-center">
        <Avatar
          sm
          style={{ marginRight: '0.5rem' }}
          avatar={props.data.avatar}
          type="charity"
        />
        <div className="flex-column">
          {props.children}
          <div className="text-[0.8rem] text-gray-500">
            EIN: {props.data.ein} | {props.data.city}, {props.data.state}
          </div>
        </div>
      </div>
    </components.Option>
  );
};

export default class CharitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
      inputValue: '',
      dropdownOpen: false,
      location: '',
      topics: [],
      categories: [],
      selectedCountry: 'United States',
    };

    this.debouncedFetchResults = debounce(this.fetchResults, 300);
    this.searchContainerRef = React.createRef();
  }

  fetchResults = async (inputValue, callback) => {
    const { location, selectedCountry, topics, categories } = this.state;

    const filters = {
      query: inputValue,
      location: location,
      country: selectedCountry,
      topics: topics,
      categories: categories,
    };
    if (inputValue || location) {
      try {
        const seeAllLabel = getSeeAllLabel(inputValue, location);
        const searchCharity = await this.props.searchStore.updateAndRunSearch(
          filters,
        );

        const results = searchCharity
          .map((charity) => ({
            ...charity,
            value: charity.id,
            label: charity.name,
          }))
          .concat([
            {
              value: 'see-all-results',
              label: seeAllLabel,
            },
          ]);
        callback(results);
      } catch (err) {
        console.error(err);
      }
    } else {
      // eslint-disable-next-line
      callback([]);
    }
  };

  loadOptions = (inputValue, callback) => {
    this.debouncedFetchResults(inputValue, callback);
  };

  removeValue = (index) => {
    let newValue = this.state.value.slice();
    newValue.splice(index, 1);
    this.setState({ value: newValue });
  };

  handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      this.setState({
        inputValue: newValue,
        dropdownOpen: true,
      });
    }
    return newValue;
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.setState({ dropdownOpen: false });

      // Close mobile keyboard
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      navigate('/donate/search', {
        state: {
          query: this.state.inputValue,
          location: this.state.location,
          country: this.state.selectedCountry,
        },
      });
    }
  };

  handleChange = (value) => {
    navigate(`/charity/${value?.id}`);
    this.setState({ value: value });
  };

  handleCountryChange = (value) => {
    this.setState({ selectedCountry: value });
  };

  handleLocationChange = (e) => {
    this.setState({ location: e.target.value }, () => {
      this.setState({ inputValue: this.state.inputValue, dropdownOpen: true });
    });
  };

  CustomClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <span style={{ cursor: 'pointer', color: '#171644' }}>✕</span>
      </components.ClearIndicator>
    );
  };

  handleOverlayClick = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: false });
  };
  renderOverlay() {
    const { dropdownOpen } = this.state;
    if (!dropdownOpen) return null;
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          inset: 0,
          overflow: 'hidden',
          width: '100vw',
          height: '100vh',

          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          zIndex: 1001,
        }}
        className="search-overlay"
        onClick={this.handleOverlayClick}
        onMouseDown={this.handleOverlayClick}
        onTouchEnd={this.handleOverlayClick}
      />
    );
  }

  render() {
    const asyncSelectKey = `${this.state.location}-${this.state.selectedCountry}`;

    return (
      <>
        {this.renderOverlay()}
        <div
          className={classNames(
            'w-full z-[1002] bg-white search-container relative',
          )}
          id="search-container"
          ref={this.searchContainerRef}
        >
          <div className=" flex justify-center items-center max-[380px]:px-2 px-4  py-3.5 ">
            <SearchIcon />
          </div>
          <AsyncSelect
            key={asyncSelectKey}
            value={this.state.value}
            placeholder="Search Cauze"
            loadOptions={this.loadOptions}
            defaultOptions
            cacheOptions={false}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: colors.baseColor,
                primary50: colors.lightGrey,
                primary25: colors.white,
                danger: colors.baseColor,
              },
            })}
            menuIsOpen={this.state.dropdownOpen}
            inputValue={this.state.inputValue}
            styles={GlobalCharityAsyncSelectStyles}
            onInputChange={this.handleInputChange}
            components={{
              ...animatedComponents,
              Option,
              Menu: (props) => (
                <Menu {...props} location={this.state.location} />
              ),
              MultiValueContainer,
              ClearIndicator: this.CustomClearIndicator,
              LoadingIndicator: () => null,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            isClearable={true}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onMenuClose={() => this.setState({ dropdownOpen: false })}
            onMenuOpen={() => this.setState({ dropdownOpen: true })}
            menuPortalTarget={document.getElementById('search-container')}
          />
          <input
            className="search-input-location"
            type="search"
            name="location"
            value={this.state.location}
            autoComplete="off"
            placeholder={'City/Zip Code'}
            onChange={this.handleLocationChange}
            onKeyDown={this.handleKeyDown}
          />
          <CountrySelect
            options={countryWhitelist}
            selectedValue={this.state.selectedCountry}
            onChange={this.handleCountryChange}
          />

          {this.props.showSelectionList && this.state.value && (
            <div className="search-result-container">
              {this.state.value?.map((charity) => (
                <div
                  key={`${charity?.id}`}
                  className="flex-row flex-align-center search-result-row font-agenda"
                >
                  <Avatar avatar={charity.avatar} entityType={'CHARITY'} />
                  <div className="flex-expand flex-column">
                    <div className="flex-row font-agenda-bold">
                      {charity.name}
                    </div>
                    <div className="flex-row result-detail">
                      {charity.location} | EIN: {charity.ein}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}
