import React, { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import AutoLinks from 'quill-auto-links';
import { linkify } from 'util/stringUtils';
import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import _ from 'lodash';
import { ReactComponent as RightArrowIcon } from '../../assets/images/icons/svgs/right-arrow.svg';
import { ReactComponent as NonProfitIcon } from '../../assets/images/icons/svgs/non-profit.svg';
import { ReactComponent as PencilIcon } from '../../assets/images/icons/svgs/pencil-cauze.svg';
import { convertEntityTypeToId } from 'util/contextUtils';
import Button from 'components/Button/Button';
import DatePicker from 'components/DatePicker';
import CharitySearch from 'components/CharitySearch/CharitySearch';
import ImageUploadModal from 'components/modals/ImageUploadModal/ImageUploadModal';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import ErrorModal from 'components/modals/ErrorModal/';
import AmountSelector from 'components/AmountSelector/AmountSelector';
import Currency from 'components/Currency/Currency';
import LinkPreview from 'components/LinkPreview/LinkPreview';
import placeholder from 'assets/images/placeholders/placeholder-image.svg';
import Avatar from 'components/Avatar/Avatar';
import { ReactComponent as EllipsisIcon } from 'assets/images/icons/dots/three-dots-hollow.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

Quill.register('modules/autoLinks', AutoLinks);

const scrubLink = _.debounce((text, update) => {
  const links = linkify.find(text);
  if (links.length > 0 && links[0].href) {
    update({ previewUrl: links[0].href });
  }
}, 1000);

const modules = {
  autoLinks: true,
  toolbar: [['bold', 'italic', 'underline'], ['link']],
};

const formats = ['bold', 'italic', 'underline', 'link'];

const InputContainer = ({ title, error, className, children, style }) => (
  <div style={style} className={classnames(' flex-column', className)}>
    {title && (
      <p
        className={classnames('input-title flex-column', {
          'input-title-error': error,
        })}
      >
        {title}
      </p>
    )}
    {children}
  </div>
);

const validate = ({
  name,
  charities,
  description,
  startDate,
  endDate,
  hasDates,
  hasMatch,
  _matchTotal,
  userMatchLimit,
}) => {
  const validationObj = {
    name: true,
    image: true,
    description: true,
    charities: true,
    dates: true,
    match: true,
    isValid: true,
  };

  if (!name) {
    validationObj.name = false;
    validationObj.isValid = false;
  }
  if (charities.length === 0) {
    validationObj.charities = false;
    validationObj.isValid = false;
  }
  if (!description) {
    validationObj.description = false;
    validationObj.isValid = false;
  }
  if (hasDates && (!startDate || !endDate)) {
    validationObj.dates = false;
    validationObj.isValid = false;
  }
  if (hasMatch && userMatchLimit === 0) {
    validationObj.match = false;
    validationObj.isValid = false;
  }

  // image is always valid because there is a default image
  return validationObj;
};

const CreateCauzeView = ({
  cauzeStore,
  profileStore,
  userContext,
  eventId,
  uiStore,
}) => {
  const [isOpenSearch, setIsOpenSearch] = useState(true);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [showImageOrVideoModal, setShowImageOrVideoModal] = useState(false);
  const [showVideoInput, setShowVideoInput] = useState(false);
  const [validation, setValidation] = useState({
    name: true,
    image: true,
    description: true,
    charities: true,
    dates: true,
    match: true,
    isValid: true,
  });
  const hasVideo =
    cauzeStore.currentCauze &&
    cauzeStore.currentCauze.previewUrl &&
    (cauzeStore.currentCauze.previewUrl.includes('youtu') ||
      cauzeStore.currentCauze.previewUrl.includes('vimeo'));

  // This is my approximation of componentWillReceiveProps comparing props to
  // see if userContext changed. useEffect with a mutable ref to check first run is
  // functionally the same
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (userContext) {
      // .current is a magic useRef thing thats attached to the ref with the default value (true)
      if (isFirstRun.current) {
        isFirstRun.current = false;
      } else {
        cauzeStore.onCreateUnmount();
        cauzeStore.navToCauzes(userContext);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sets video state when loading in an existing cauze for editing
  useEffect(() => {
    if (hasVideo && !showVideoInput) {
      setShowVideoInput(true);
    }
  }, [hasVideo, showVideoInput]);

  const isEditing = !!eventId;

  const handleSubmit = (event, draft = false) => {
    event.preventDefault();

    const validationResult = validate({
      ...cauzeStore.currentCauze,
    });

    if (validationResult.isValid) {
      if (isEditing) {
        cauzeStore.updatePlannedEvent({
          eventState: draft ? 'UNPUBLISHED' : 'ACTIVE',
          eventId,
          userContext,
        });
      } else {
        cauzeStore.createPlannedEvent({
          userContext,
          eventState: draft ? 'UNPUBLISHED' : 'ACTIVE',
        });
      }
    } else {
      window.scrollTo(0, 0);
    }
    setValidation(validationResult);
  };

  const currentCauze = cauzeStore.currentCauze;

  // Only allow match details to be configured for creating a corporate cauze.
  const showMatchAmounts = userContext.companyId || userContext.userId;
  const matchTotalDifference = cauzeStore.getMatchTotalDifference();
  const checkMatchDetails =
    (currentCauze.hadPreviousMatch && cauzeStore.matchHasChanged) ||
    (!currentCauze.hadPreviousMatch && currentCauze.hasMatch);
  const isGroup = profileStore.getActiveEntity().groupType !== 'COMPANY';

  const toggleDropdown = () => setIsOpenSearch((prev) => !prev);
  return (
    <div className="create-cauze flex-column">
      <ErrorModal
        onToggleClose={cauzeStore.resetImage}
        errorText="There was an issue uploading the image. Support has been notified."
        isOpen={cauzeStore.imageUploadError}
      />
      <ErrorModal
        onToggleClose={cauzeStore.clearCauzeErrors}
        errorText="There was an issue publishing your cauze. Support has been notified."
        isOpen={cauzeStore.createCauzeError}
      />
      <ImageUploadModal
        updateImageUrl={(previewImageUrl) => {
          cauzeStore.updateCauze({
            previewImageUrl,
            externalImageUrl: null,
            previewUrl: null,
          });
        }}
        onSave={() => setShowImageOrVideoModal(false)}
        store={cauzeStore}
        isActive={showImageUploadModal}
        onToggleClose={() => setShowImageUploadModal(false)}
        src={placeholder}
      />

      <div>
        <section className="container">
          <div className="text-center font-agenda-bold max-sm:mb-4 ">
            <h1 className="text-[24px] leading-[29px] uppercase">
              {isEditing ? 'Edit your Cauze' : 'START A CAUZE'}
            </h1>
          </div>
          {cauzeStore.loadingCauze ? (
            <CauzeSpinner />
          ) : (
            <div className="p-8 max-sm:p-0">
              <form onSubmit={handleSubmit} className="gap-5">
                <div>
                  <InputContainer title="Cauze Title" error={!validation.name}>
                    <input
                      className="input-element"
                      type="text"
                      name="title"
                      value={currentCauze.name}
                      placeholder={`${profileStore.activeEntity.name}'s Cauze`}
                      onChange={(e) => {
                        setValidation({
                          ...validation,
                          name: true,
                          isValid: true,
                        });
                        cauzeStore.updateCauze({ name: e.target.value });
                      }}
                    />
                  </InputContainer>
                </div>
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={toggleDropdown}
                >
                  <div className="flex gap-2.5 items-center">
                    <NonProfitIcon className="" />
                    <div className="font-agenda flex justify-center items-center text-[16px] translate-y-[1px]">
                      Non-Profits
                    </div>
                  </div>
                  <div className="flex gap-2.5 items-center justify-center ">
                    {cauzeStore.currentCauze &&
                    cauzeStore.currentCauze.charities &&
                    cauzeStore.currentCauze.charities.length > 0 ? (
                      <>
                        {' '}
                        <div className="event-item-avatar-group">
                          {cauzeStore?.currentCauze?.charities
                            ?.slice(0, 5)
                            .map((charity) => (
                              <Avatar
                                xsm
                                avatar={charity.avatar}
                                entityType={'CHARITY'}
                              />
                            ))}
                          {cauzeStore?.currentCauze?.charities >= 5 && (
                            <EllipsisIcon className="event-item-ellipsis" />
                          )}
                        </div>{' '}
                      </>
                    ) : (
                      <div className="translate-y-[1px] text-[14px] flex items-center font-agenda-bold">
                        Search
                      </div>
                    )}
                    <RightArrowIcon
                      className={`transition-transform duration-300 ${
                        isOpenSearch ? 'rotate-90' : ''
                      }`}
                    />
                  </div>
                </div>
                <div className={` ${isOpenSearch ? 'block' : 'hidden'}`}>
                  <InputContainer
                    title="Choose Nonprofit(s)"
                    error={!validation.charities}
                  >
                    <CharitySearch
                      defaultValue={toJS(currentCauze.charities)}
                      showSelectionList
                      onChange={(charities) => {
                        setValidation({
                          ...validation,
                          charities: true,
                          isValid: true,
                        });
                        cauzeStore.updateCauze({
                          charities,
                        });
                      }}
                    />
                    <div className="flex items-center gap-1 text-gray-500 text-sm mt-1">
                      <FontAwesomeIcon icon={faInfoCircle} />
                      <span className="text-xs">
                        A Cauze can support one or more nonprofits
                      </span>
                    </div>
                  </InputContainer>
                </div>
                <div className="flex max-sm:flex-col gap-4 py-2.5  border-y-[1px] border-x-0 border-[#ddd] border-solid">
                  {showVideoInput && (
                    <div className="flex-column ">
                      <input
                        type="text"
                        name="video"
                        style={{
                          border: '1px solid hsl(0,0%,80%)',
                          borderRadius: '4px',
                          margin: '0.5rem',
                        }}
                        value={currentCauze.previewUrl}
                        placeholder={'Youtube or Vimeo Video Link'}
                        onChange={(e) => {
                          scrubLink(e.target.value, cauzeStore.updateCauze);
                          cauzeStore.updateCauze({
                            previewUrl: e.target.value,
                          });
                        }}
                      />
                      {hasVideo && (
                        <LinkPreview
                          style={{ margin: '0.5rem' }}
                          smallPreview
                          uri={currentCauze.previewUrl}
                          onRemovePreview={() =>
                            cauzeStore.updateCauze({ previewUrl: '' })
                          }
                          onPreviewLoaded={({ previewImageUrl }) =>
                            cauzeStore.updateCauze({
                              externalImageUrl: previewImageUrl,
                            })
                          }
                        />
                      )}
                    </div>
                  )}

                  {!showVideoInput && (
                    <div
                      className="column flex-column flex-center h-[224px] min-w-[224px] self-start !p-0"
                      style={{ position: 'relative' }}
                    >
                      <img
                        className="h-full w-full p-0 object-contain"
                        onClick={() => setShowImageOrVideoModal(true)}
                        style={{ cursor: 'pointer' }}
                        src={
                          currentCauze.previewImageUrl !== ''
                            ? currentCauze.previewImageUrl
                            : placeholder
                        }
                      />
                      {currentCauze.previewImageUrl && (
                        <PencilIcon
                          onClick={() => setShowImageOrVideoModal(true)}
                          className="absolute top-2 left-2"
                        />
                      )}
                      {cauzeStore.isUploadingCauzeImage && (
                        <CauzeSpinner
                          size={'50px'}
                          style={{
                            position: 'absolute',
                            top: 'calc(50% - 25px)',
                            left: 0,
                            right: 0,
                          }}
                        />
                      )}
                    </div>
                  )}
                  <CauzeImageOrVideo
                    currentCauze={currentCauze}
                    cauzeStore={cauzeStore}
                    hasVideo={hasVideo}
                    validation={validation}
                    setShowVideoInput={setShowVideoInput}
                    showVideoInput={showVideoInput}
                    setShowImageUploadModal={setShowImageUploadModal}
                    store={cauzeStore}
                    onToggleClose={() => setShowImageOrVideoModal(false)}
                    isActive={showImageOrVideoModal}
                  />
                  <InputContainer
                    className="self-center w-full"
                    title="Why are you raising money?"
                    error={!validation.description}
                  >
                    <ReactQuill
                      formats={formats}
                      modules={modules}
                      theme="snow"
                      defaultValue={
                        currentCauze.description ||
                        'Let’s make an impact together! Consider donating with me to show your support, even if it’s just a dollar. Collectively we can make a difference.'
                      }
                      onChange={(description) => {
                        setValidation({
                          ...validation,
                          description: true,
                          isValid: true,
                        });
                        cauzeStore.updateCauze({ description });
                      }}
                    />
                  </InputContainer>
                </div>

                <InputContainer title="Cauze Dates" error={!validation.dates}>
                  <div>
                    <label style={{ paddingLeft: '1rem' }} htmlFor="no-dates">
                      <input
                        onChange={(_ev) => {
                          cauzeStore.toggleHasDates();
                        }}
                        type="radio"
                        id="no-dates"
                        checked={!currentCauze.hasDates}
                        name="no-dates"
                        value="no-dates"
                      />
                      No Dates
                    </label>
                  </div>
                  <div>
                    <label style={{ paddingLeft: '1rem' }} htmlFor="show-dates">
                      <input
                        onChange={(_ev) => {
                          cauzeStore.toggleHasDates();
                        }}
                        type="radio"
                        id="show-dates"
                        name="show-dates"
                        value="show-dates"
                        checked={currentCauze.hasDates}
                      />
                      Show Dates
                    </label>
                    {currentCauze.hasDates && (
                      <DatePicker
                        startDate={currentCauze.startDate}
                        endDate={currentCauze.endDate}
                        onDatesChange={cauzeStore.updateCauze}
                        noBorder
                      />
                    )}
                  </div>
                </InputContainer>
                {showMatchAmounts && (
                  <InputContainer title="Cauze Match" error={!validation.match}>
                    {!currentCauze.hadPreviousMatch && (
                      <div>
                        <label
                          style={{ paddingLeft: '1rem' }}
                          htmlFor="no-match"
                        >
                          <input
                            onChange={(_ev) => {
                              cauzeStore.updateCauze({ hasMatch: false });
                            }}
                            type="radio"
                            id="no-match"
                            checked={!currentCauze.hasMatch}
                            name="no-match"
                            value="no-match"
                          />
                          Do not offer match
                        </label>
                      </div>
                    )}
                    <div>
                      {!currentCauze.hadPreviousMatch && (
                        <label
                          style={{ paddingLeft: '1rem' }}
                          htmlFor="show-match"
                        >
                          <input
                            onChange={(_ev) => {
                              cauzeStore.updateCauze({ hasMatch: true });
                            }}
                            type="radio"
                            id="show-match"
                            name="show-match"
                            value="show-match"
                            checked={currentCauze.hasMatch}
                          />
                          Set up match
                        </label>
                      )}
                      {currentCauze.hasMatch && (
                        <div
                          className={classnames({
                            'existing-match': currentCauze.hadPreviousMatch,
                            'match-container': !currentCauze.hadPreviousMatch,
                          })}
                        >
                          <div
                            className="flex-column"
                            style={{ marginTop: '0.5rem' }}
                          >
                            <label htmlFor="all-users">
                              <input
                                onChange={(_ev) => {
                                  cauzeStore.updateCauze({
                                    matchType: 'EVENT',
                                  });
                                }}
                                type="radio"
                                id="all-users"
                                name="all-users"
                                value="all-users"
                                checked={currentCauze.matchType === 'EVENT'}
                              />
                              Set match for all Cauze users
                            </label>
                            <label htmlFor="employee-only">
                              <input
                                onChange={(_ev) => {
                                  cauzeStore.updateCauze({
                                    matchType: 'COMPANY',
                                  });
                                }}
                                type="radio"
                                id="employee-only"
                                name="employee-only"
                                value="employee-only"
                                checked={currentCauze.matchType === 'COMPANY'}
                              />
                              Set match for {isGroup ? 'members' : 'employees'}{' '}
                              only
                            </label>
                            <label htmlFor="follower-only">
                              <input
                                onChange={(_ev) => {
                                  cauzeStore.updateCauze({
                                    matchType: 'FOLLOWER',
                                  });
                                }}
                                type="radio"
                                id="follower-only"
                                name="follower-only"
                                value="follower-only"
                                checked={currentCauze.matchType === 'FOLLOWER'}
                              />
                              Set match for only my Cauze followers
                            </label>
                          </div>
                          <div className="amounts-container">
                            <p className="option-group-subhead text-bold">
                              Maximum Per Giver
                            </p>
                            <div style={{ marginLeft: '1rem' }}>
                              <div className="flex-column match-multiplier">
                                <div style={{ marginTop: '0.25rem' }}>
                                  For Every $1 Donated Offer
                                </div>
                                <label htmlFor="1x-multiplier">
                                  <input
                                    onChange={(_ev) => {
                                      cauzeStore.setMatchHasChanged(true);
                                      cauzeStore.updateCauze({
                                        matchMultiplier: 1,
                                      });
                                    }}
                                    type="radio"
                                    id="1x-multiplier"
                                    name="1x-multiplier"
                                    value={1}
                                    checked={currentCauze.matchMultiplier === 1}
                                  />
                                  1x Match ($1 for each $1 donated by the giver)
                                </label>
                                <label htmlFor="2x-multiplier">
                                  <input
                                    onChange={(_ev) => {
                                      cauzeStore.setMatchHasChanged(true);
                                      cauzeStore.updateCauze({
                                        matchMultiplier: 2,
                                      });
                                    }}
                                    type="radio"
                                    id="2x-multiplier"
                                    name="2x-multiplier"
                                    value={2}
                                    checked={currentCauze.matchMultiplier === 2}
                                  />
                                  2x Match ($2 for each $1 donated by the giver)
                                </label>
                                <label htmlFor="3x-multiplier">
                                  <input
                                    onChange={(_ev) => {
                                      cauzeStore.setMatchHasChanged(true);
                                      cauzeStore.updateCauze({
                                        matchMultiplier: 3,
                                      });
                                    }}
                                    type="radio"
                                    id="3x-multiplier"
                                    name="3x-multiplier"
                                    value={3}
                                    checked={currentCauze.matchMultiplier === 3}
                                  />
                                  3x Match ($3 for each $1 donated by the giver)
                                </label>
                                <p style={{ marginTop: '0.25rem' }}>
                                  Match {currentCauze.matchMultiplier}x
                                </p>
                                <div
                                  style={{ marginRight: '2rem' }}
                                  className="flex-row flex-align-center underline"
                                >
                                  $
                                  <input
                                    type="number"
                                    name="userMatchLimit"
                                    value={
                                      currentCauze.userMatchLimit === 0
                                        ? null
                                        : currentCauze.userMatchLimit / 100
                                    }
                                    placeholder={50}
                                    onChange={(e) => {
                                      cauzeStore.setMatchHasChanged(true);
                                      cauzeStore.updateCauze({
                                        userMatchLimit: e.target.value * 100,
                                      });
                                    }}
                                  />
                                </div>
                                <p className="option-group-subhead flex-row text-bold">
                                  Match {currentCauze.matchMultiplier}x&nbsp;
                                  <Currency
                                    amount={currentCauze.userMatchLimit}
                                  />
                                  &nbsp;= up to&nbsp;
                                  <Currency
                                    amount={
                                      currentCauze.userMatchLimit *
                                      currentCauze.matchMultiplier
                                    }
                                  />
                                  &nbsp;per giver
                                </p>
                              </div>
                            </div>

                            <hr />

                            <p className="option-group-subhead text-bold">
                              Maximum Total Amount
                            </p>
                            {!profileStore.activeEntity.balance
                              .allowNegative && (
                              <p>
                                Non-employee matches require being fully funded
                                when you set up the match.
                              </p>
                            )}
                            <AmountSelector
                              amounts={[50000, 100000, 500000]}
                              onAmountUpdate={(amount) => {
                                setValidation({
                                  ...validation,
                                  match: true,
                                  isValid: true,
                                });
                                cauzeStore.setMatchHasChanged(true);
                                cauzeStore.updateCauze({
                                  matchTotal: amount,
                                });
                              }}
                              defaultAmount={currentCauze.matchTotal || 50000}
                              sendDefaultUpdate={false}
                            />
                            <p className="option-group-subhead flex-row">
                              Match up to&nbsp;
                              <span className="text-bold">
                                <Currency amount={currentCauze.matchTotal} />
                              </span>
                              &nbsp;Total
                            </p>
                            {checkMatchDetails &&
                              !profileStore.activeEntity.balance
                                .allowNegative &&
                              matchTotalDifference >
                                profileStore.activeEntity.balance.total && (
                                <div className="flex-column add-funds-warning">
                                  <div>
                                    <FontAwesomeIcon
                                      icon={faExclamationCircle}
                                      aria-hidden="true"
                                      className="error"
                                    />
                                    <strong>
                                      You must add funds in order to continue
                                    </strong>
                                  </div>
                                  <div className="flex-row flex-align-center">
                                    <strong>
                                      Your Cauze balance:&nbsp;
                                      <Currency
                                        amount={
                                          profileStore.activeEntity.balance
                                            .total
                                        }
                                      />
                                    </strong>
                                    <Button
                                      sm
                                      className="add-funds is-small"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        uiStore.openModal('SEND_GIFTS', {
                                          defaultAmount:
                                            matchTotalDifference -
                                            profileStore.activeEntity.balance
                                              .total,
                                          giftType: 'SELF_GIFT',
                                          ...convertEntityTypeToId({
                                            entityType:
                                              profileStore.activeEntity
                                                .entityType,
                                            id: profileStore.activeEntity.id,
                                          }),
                                        });
                                      }}
                                    >
                                      Add Funds
                                    </Button>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </InputContainer>
                )}
                <div className="flex-row items-center w-full mx-2.5 font-agenda-bold mt-5">
                  {currentCauze.eventState === 'UNPUBLISHED' && (
                    <Button
                      onClick={(ev) => handleSubmit(ev, true)}
                      style={{ marginRight: '1rem' }}
                      white
                      className="create-button hover:bg-[#eee]"
                      isSubmitting={cauzeStore.savingCauze}
                      disabled={
                        !validation.isValid ||
                        (currentCauze.hasMatch &&
                          checkMatchDetails &&
                          !profileStore.activeEntity.balance.allowNegative &&
                          matchTotalDifference >
                            profileStore.activeEntity.balance.total)
                      }
                    >
                      Save as Draft
                    </Button>
                  )}
                  <Button
                    className="create-button !border-cauzeorange"
                    type="submit"
                    isSubmitting={cauzeStore.savingCauze}
                    disabled={
                      !validation.isValid ||
                      (currentCauze.hasMatch &&
                        checkMatchDetails &&
                        !profileStore.activeEntity.balance.allowNegative &&
                        matchTotalDifference >
                          profileStore.activeEntity.balance.total)
                    }
                  >
                    {!isEditing && `Save & `}Publish
                  </Button>
                </div>
              </form>
            </div>
          )}
          <div className="dashboard flex-row flex-space-around"></div>
        </section>
      </div>
    </div>
  );
};

export default inject(
  'cauzeStore',
  'profileStore',
  'uiStore',
)(observer(CreateCauzeView));
const CauzeImageOrVideo = ({
  cauzeStore,
  currentCauze,
  validation,
  setShowImageUploadModal,
  hasVideo,
  showVideoInput,
  setShowVideoInput,
  onToggleClose,
  isActive,
}) => {
  return (
    <div
      className={classnames('modal image-upload-modal !z-30', {
        'is-active': isActive,
      })}
    >
      <div onClick={onToggleClose} className="modal-background"></div>
      <div style={{ backgroundColor: 'white' }} className="modal-card">
        <header className="modal-card-head flex-center">
          <button
            className="delete"
            type="button"
            aria-label="close"
            onClick={onToggleClose}
          ></button>
          <div className="flex-column flex-center">
            <div className="text-center text-bold">Cauze Image or Video</div>
          </div>
        </header>
        <section className="modal-card-body">
          <InputContainer error={!validation.image}>
            <div className="flex max-sm:flex-col">
              <div className="column">
                <div>
                  <input
                    onChange={(_ev) => {
                      cauzeStore.updateCauze({
                        previewImageUrl: '',
                        imageId: null,
                        externalImageUrl: null,
                        previewUrl: null,
                      });
                      setShowVideoInput(false);
                    }}
                    type="radio"
                    id="default-image"
                    checked={
                      currentCauze.previewImageUrl === '' && !showVideoInput
                    }
                    name="cauze-image"
                    value="default-image"
                  />
                  <label htmlFor="default-image">Use default image</label>
                </div>
                <div>
                  <input
                    onChange={(_ev) => {
                      setShowImageUploadModal(true);
                    }}
                    type="radio"
                    id="upload-image"
                    name="cauze-image"
                    value="upload-image"
                    checked={
                      currentCauze.previewImageUrl !== '' && !showVideoInput
                    }
                  />
                  <label htmlFor="upload-image">Upload an Image</label>
                </div>
                <div>
                  <input
                    onChange={(_ev) => setShowVideoInput(true)}
                    type="radio"
                    id="default-image"
                    checked={showVideoInput}
                    name="cauze-image"
                    value="default-image"
                  />
                  <label htmlFor="default-image">YouTube/Vimeo Link</label>
                </div>
                {showVideoInput && (
                  <div className="flex-column">
                    <input
                      type="text"
                      name="video"
                      style={{
                        border: '1px solid hsl(0,0%,80%)',
                        borderRadius: '4px',
                        margin: '0.5rem',
                      }}
                      value={currentCauze.previewUrl}
                      placeholder={'Youtube or Vimeo Video Link'}
                      onChange={(e) => {
                        scrubLink(e.target.value, cauzeStore.updateCauze);
                        cauzeStore.updateCauze({
                          previewUrl: e.target.value,
                        });
                      }}
                    />
                    {hasVideo && (
                      <LinkPreview
                        style={{ margin: '0.5rem' }}
                        smallPreview
                        uri={currentCauze.previewUrl}
                        onRemovePreview={() =>
                          cauzeStore.updateCauze({ previewUrl: '' })
                        }
                        onPreviewLoaded={({ previewImageUrl }) =>
                          cauzeStore.updateCauze({
                            externalImageUrl: previewImageUrl,
                          })
                        }
                      />
                    )}
                  </div>
                )}
              </div>
              {!showVideoInput && (
                <div
                  className="column flex-column flex-center"
                  style={{ position: 'relative' }}
                >
                  <img
                    onClick={() => setShowImageUploadModal(true)}
                    style={{ cursor: 'pointer' }}
                    src={
                      currentCauze.previewImageUrl !== ''
                        ? currentCauze.previewImageUrl
                        : placeholder
                    }
                  />
                  {cauzeStore.isUploadingCauzeImage && (
                    <CauzeSpinner
                      size={'50px'}
                      style={{
                        position: 'absolute',
                        top: 'calc(50% - 25px)',
                        left: 0,
                        right: 0,
                      }}
                    />
                  )}
                  <a
                    onClick={(ev) => {
                      ev.preventDefault();
                      setShowImageUploadModal(true);
                    }}
                  >
                    View/Edit Image
                  </a>
                </div>
              )}
            </div>
          </InputContainer>
        </section>
      </div>
    </div>
  );
};
