import React, { useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate, useLocation } from '@reach/router';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import CharityDetailsModal from 'components/modals/CharityDetailsModal';
import LoginModal from 'components/modals/LoginModal/LoginModal';
import useSearch from 'hooks/useSearch';
import SearchItem from 'components/SearchItem/SearchItem';
import withUserContext from 'behaviors/withUserContext';
import mixpanel from 'mixpanel-browser';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import TopicList from 'components/TopicList/TopicList';
import { toJS } from 'mobx';
import useDashboardStore from 'stores/DashboardStore';

const PAGE_SIZE = 12;
const INTERSTITIAL_INDEX = 3;

const SearchView = ({ activeEntity, searchStore, uiStore, authStore }) => {
  const location = useLocation();
  const { setProps } = useDashboardStore();
  useSearch();

  const [detailCharity, setDetailCharity] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const showResults = searchStore.results.length > 0 && !searchStore.loading;
  const showEmpty = searchStore.results.length === 0 && !searchStore.loading;
  const canLoadMore = searchStore.page * PAGE_SIZE < searchStore.results.length;

  const onDonate = (charityId) => {
    mixpanel.track('Search Result Click', {
      channel: 'web',
      type: 'charity',
      id: charityId,
      withGift: !!uiStore.savedUiState.userToken,
    });

    if (uiStore.savedUiState.userToken) {
      uiStore.openModal('CHECKOUT_WITH_GIFT', {
        giftToken: uiStore.savedUiState?.userToken?.token,
        hideAmount: uiStore.savedUiState?.userToken?.gift?.hideAmount,
        giftAmount: uiStore.savedUiState?.userToken?.gift?.amount,
        email: uiStore.savedUiState?.userToken?.email,
        initiatorId: uiStore.savedUiState.userToken?.gift?.initiatorCompany?.id,
        charityId,
      });
    } else {
      navigate(`/checkout/charity/${charityId}`);
    }
  };

  const showUnauthedPrompt = () => {
    uiStore.openModal('CONFIRM', {
      message: 'Please Login or Signup to follow givers',
      action: () => {
        setShowLoginModal(true);
      },
      confirmText: 'Login / Signup',
      cancelText: 'Not Now',
    });
  };

  const topics = useMemo(() => {
    searchStore.topics.concat(searchStore.categories);

    const _topics = toJS(searchStore.topics);
    const _categories = toJS(searchStore.categories);

    return [..._topics, ..._categories];
  }, [searchStore.topics, searchStore.categories]);

  useEffect(() => {
    searchStore.updateAndRunSearch({
      query: location.state?.query || '',
      location: location.state?.location || '',
      country: location.state?.country || '',
      topics: [location.state?.topic].filter((t) => t),
    });
  }, [location]);

  useEffect(() => {
    setProps({
      title: 'Your Search Results',
    });
  }, []);

  return (
    <div className="search-view flex-column">
      <div>
        {showDetails && (
          <CharityDetailsModal
            isOpen={showDetails}
            mini
            onToggleClose={() => {
              setShowDetails(false);
              setDetailCharity(null);
            }}
            charity={detailCharity}
          />
        )}
        <LoginModal
          isOpen={showLoginModal}
          onToggleClose={() => setShowLoginModal(false)}
        />
        <div>
          <div className="results-container">
            <TopicList
              title="Trending Topics"
              topics={topics}
              activeTopics={searchStore.currentSearch.topics}
              loading={searchStore.loading}
            />
            {searchStore.loading && <CauzeSpinner fullscreen />}
            {showEmpty && (
              <div className="empty-item flex-column flex-align-center">
                <div className="flex-row">
                  <div className="flex-column">
                    <p className="text-bold">Sorry, We Couldn't Find a Match</p>
                    <p className="text-normal">
                      Try a different search item or check out
                      <br />
                      some of our suggestions below
                    </p>
                  </div>
                </div>
                <div className="flex-row flex-justify-end flex-align-center"></div>
              </div>
            )}
            {showResults &&
              searchStore.results
                .slice(0, INTERSTITIAL_INDEX)
                .map((charity, index) => (
                  <SearchItem
                    charity={charity}
                    userContext={activeEntity.userContext}
                    onDonate={() => onDonate(charity.id)}
                    onLearnMore={() => {
                      setDetailCharity(charity);
                      setShowDetails(true);
                    }}
                    onFollowCharity={searchStore.followCharity}
                    onFollowGiver={searchStore.onFollowGiver}
                    key={`${index}-${charity.name}`}
                    index={index}
                    isAuthenticated={authStore.isAuthenticated}
                    setShowLoginModal={setShowLoginModal}
                    onShowUnauthedPrompt={showUnauthedPrompt}
                    authStore={authStore}
                  />
                ))}
          </div>
        </div>
        <div>
          <div className="results-container">
            {showResults &&
              searchStore.results
                .slice(INTERSTITIAL_INDEX, searchStore.page * PAGE_SIZE)
                .map((charity, index) => (
                  <SearchItem
                    charity={charity}
                    userContext={activeEntity.userContext}
                    onDonate={() => onDonate(charity.id)}
                    onLearnMore={() => {
                      setDetailCharity(charity);
                      setShowDetails(true);
                    }}
                    onFollowFollower={searchStore.followCharity}
                    onFollowCharity={searchStore.followCharity}
                    onFollowGiver={searchStore.onFollowGiver}
                    key={`${index + INTERSTITIAL_INDEX}-${charity.name}`}
                    index={index + INTERSTITIAL_INDEX}
                    isAuthenticated={authStore.isAuthenticated}
                    onShowUnauthedPrompt={showUnauthedPrompt}
                  />
                ))}
            {canLoadMore && (
              <div className="w-full flex">
                <div className="mx-auto">
                  <CauzeButton
                    white
                    large
                    onClick={searchStore.loadMoreSearchItems}
                    style={{ marginTop: '5rem' }}
                  >
                    Load More Results
                  </CauzeButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'searchStore',
    'donationLandingStore',
    'uiStore',
    'authStore',
  )(observer(SearchView)),
);
