import React, { useState } from 'react';
import { navigate } from '@reach/router';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';

import Avatar from 'components/Avatar/Avatar';
import FeedCardMatch from './FeedCardMatch';
import { getEntityUrl } from 'util/navHelpers';

import { ReactComponent as HeartOutline } from 'assets/images/icons/heart/heart-outline.svg';
import { ReactComponent as HeartFilled } from 'assets/images/icons/heart/heart-red.svg';
import { ReactComponent as CommentIcon } from 'assets/images/icons/svgs/comment.svg';

import { ReactComponent as GiveIcon } from 'assets/images/icons/svgs/give-bold.svg';
import { ReactComponent as ShareIcon } from 'assets/images/icons/share/share-grey-thick.svg';
import ActorVerbSubjectText from 'components/ActorVerbSubjectText';
import FeedCardCauze from './FeedCardCauze';
import Currency from 'components/Currency/Currency';
import FeedItemActionMenu from './FeedItemActionMenu';
import { inject, observer } from 'mobx-react';

const FeedItemAction = ({
  Icon,
  text,
  iconClassName,
  onClick,
  textClassName = 'text-xs leading-none font-agenda text-text-light mt-auto',
}) => {
  return (
    <div
      className="text-center h-[37px] grid grid-rows-2 cursor-pointer"
      onClick={onClick}
    >
      <div className={iconClassName}>
        <Icon />
      </div>
      <div className={textClassName}>{text}</div>
    </div>
  );
};

const FeedItem = ({
  item,
  onLike,
  eventTitle = '',
  entity,
  index,
  uiStore,
  followingFeedStore,
  authStore,
}) => {
  let imageHref;
  const [commentCount, setCommentCount] = useState(item?.commentCount || 0);
  const [currentEntityCommented, setCurrentEntityCommented] = useState(
    (item?.currentEntityCommented && authStore.isAuthenticated) || false,
  );

  const purchaseId = item.purchaseId;
  const eventId = item.eventId;
  const entityType = item.actor?.entityType;
  const userId = item.actor?.id;
  const companyId = item.actor?.id;
  const subject = item.subjects?.[0];
  const event = item.event;
  const showEvent = event && event.eventType?.toLowerCase() === 'planned';
  const hasComment = Boolean(item?.purchaseComment);
  const isJoinedEntity =
    item?.activityEntity?.id === item?.joinedEntity?.id &&
    item?.activityEntity?.type === item?.joinedEntity?.type;
  const isJoinedActivity =
    item?.itemType === 'JOINED_ACTIVITY' &&
    item?.joinedEntity &&
    !isJoinedEntity &&
    authStore.isAuthenticated;

  if (eventId) {
    imageHref = `/event/${eventId}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  } else if (purchaseId) {
    imageHref = `/event/purchase/${purchaseId}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  } else if (item.subjects?.[0]?.id) {
    imageHref = `/event/${item.subjects[0].id}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  }

  const onJoinClick = () => {
    mixpanel.track('Feed Item Join Click', {
      ...item,
    });

    if (!eventId && purchaseId) {
      if (entityType === 'USER') {
        navigate(
          `/event/purchase/${purchaseId}?referrer_user_id=${userId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      } else if (entityType === 'COMPANY') {
        navigate(
          `/event/purchase/${purchaseId}?referrer_company_id=${companyId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      } else {
        navigate(
          `/event/purchase/${purchaseId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      }
    } else if (eventId) {
      if (entityType === 'USER') {
        navigate(
          `/event/${eventId}?referrer_user_id=${userId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      } else if (entityType === 'COMPANY') {
        navigate(
          `/event/${eventId}?referrer_company_id=${companyId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      } else {
        navigate(
          `/event/${eventId}${
            purchaseId ? `&joined_purchase_id=${purchaseId}` : ''
          }`,
        );
      }
    }
  };

  const onShareClick = () => {
    uiStore.openModal('SHARE_MODAL', {
      shareUrl: item.event?.shareLink,
      isSelf: false, // TODO
    });
  };
  const setFeedCount = (count, currentEntityCommented) => {
    setCommentCount(count);
    setCurrentEntityCommented(currentEntityCommented);
    uiStore.openModal('COMMENT', {
      commentProps: {
        purchaseId,
        feed: item,
        index: index,
        currentEntityCommented: currentEntityCommented,
        commentCount: count,
        setFeedCount,
        followingFeedStore: followingFeedStore,
      },
    });
  };

  const onCommentClick = () => {
    if (!authStore.isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          console.log('in navigate');
          navigate('/login');
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
      return;
    }
    uiStore.openModal('COMMENT', {
      commentProps: {
        purchaseId,
        feed: item,
        index: index,
        currentEntityCommented,
        commentCount,
        setFeedCount,
        followingFeedStore: followingFeedStore,
      },
    });
  };

  const isInactiveUser =
    entityType === 'USER' &&
    (!item.actor?.user?.isActive || item.actor?.user?.unclaimed);
  const AvatarContainer = isInactiveUser ? 'div' : 'a';
  const isMatchSponsor = Boolean(
    Array.isArray(item?.matchActors) &&
      item.matchActors.find(
        (matchActor) =>
          `${matchActor.id}` === `${entity?.id}` &&
          matchActor.entityType === `${entity?.groupType}`.toUpperCase(),
      ),
  );

  const hasMatch = Boolean(item.matchActors?.length) && !isMatchSponsor;

  let avatarEntity = {
    id: item.actor?.id,
    entityType: entityType,
    avatar: item.actor?.avatar,
  };

  if (isMatchSponsor) {
    avatarEntity = {
      id: entity.id,
      entityType: `${entity?.groupType}`.toUpperCase(),
      avatar: entity.avatar,
    };
  }

  const isImageExistWithCauze =
    subject && (subject.image?.lg || subject.previewUrl);
  return (
    <div className="feed-item">
      <div className={cx('feed-item-title ')}>
        <AvatarContainer
          className="my-auto h-10"
          href={isInactiveUser ? undefined : getEntityUrl(avatarEntity)}
        >
          <Avatar
            avatar={avatarEntity.avatar}
            entityType={avatarEntity.entityType}
            id={avatarEntity.id}
            onClick={null}
            noBorder
          />
        </AvatarContainer>
        <div className="my-auto leading-none">
          <ActorVerbSubjectText
            {...item}
            isAuthenticated={authStore.isAuthenticated}
            eventTitle={eventTitle}
            entity={entity}
            isMatchSponsor={isMatchSponsor}
          />
        </div>
        <div className="flex ml-auto cursor-pointer p-0 bg-transparent border-none">
          <FeedItemActionMenu
            uiStore={uiStore}
            purchaseId={purchaseId}
            isCurrentEntity={item?.activityEntity?.isCurrentEntity}
          />
        </div>
      </div>
      {showEvent && isImageExistWithCauze && (
        <div className="feed-card-cauze-container">
          <div className="feed-card-cauze-lines" />
          <FeedCardCauze
            href={imageHref}
            event={{
              ...subject,
            }}
            hasComment={hasComment || hasMatch}
          />
        </div>
      )}
      {hasMatch && (
        <div
          className={cx('feed-item-matches', {
            'has-comment': hasComment && showEvent,
          })}
        >
          <div
            className={cx('feed-item-actions-lines', {
              'has-comment': !showEvent,
            })}
          />
          <FeedCardMatch matches={item.matchActors} />
        </div>
      )}
      {hasComment ? (
        <div
          className={cx('feed-item-comment-container mb-1', {
            'no-event': !showEvent,
          })}
        >
          {isImageExistWithCauze ? (
            <div
              className={cx('feed-item-actions-lines', {
                'has-comment': !showEvent,
              })}
            />
          ) : (
            <div className="pl-12" />
          )}
          <div className="feed-item-comment">{item?.purchaseComment}</div>
        </div>
      ) : (
        <></>
      )}
      <div className="feed-item-actions">
        <div
          className={cx('feed-item-actions-lines', {
            'has-comment':
              hasComment || !showEvent || hasMatch || !isImageExistWithCauze,
          })}
        />
        <div className="flex justify-between w-full !mr-0">
          <button
            className="cz-btn-brand-dark lg:flex-1  pt-3 w-[100px] min-[380px]:w-[122px] lg:max-w-[245px]"
            onClick={onJoinClick}
            disabled={isJoinedActivity}
          >
            {isJoinedActivity ? 'JOINED' : 'JOIN'}
          </button>

          {(item?.totalActorCount || 0) >= 3 && (
            <FeedItemAction
              Icon={GiveIcon}
              text={<Currency compact amount={item?.event?.current || 0} />}
              onClick={onJoinClick}
            />
          )}
          <FeedItemAction
            Icon={CommentIcon}
            text={commentCount || 0}
            onClick={onCommentClick}
            iconClassName={cx({
              'comment-highlighted': currentEntityCommented,
            })}
            textClassName={cx('text-xs leading-none font-agenda mt-auto', {
              'text-text-light': !currentEntityCommented,
              'text-cauzeorange': currentEntityCommented,
            })}
          />
          <FeedItemAction
            Icon={
              item?.currentEntityLiked && authStore.isAuthenticated
                ? HeartFilled
                : HeartOutline
            }
            text={item?.likeCount || 0}
            onClick={() => onLike({ feedId: item?.id })}
            textClassName={cx('text-xs leading-none font-agenda mt-auto', {
              'text-text-light': !item?.currentEntityLiked,
              'text-cauzeorange':
                item?.currentEntityLiked && authStore.isAuthenticated,
            })}
          />
          <FeedItemAction
            Icon={ShareIcon}
            text="Share"
            onClick={onShareClick}
          />
        </div>
      </div>
    </div>
  );
};

export default inject('authStore')(observer(FeedItem));
