import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as DownChevron } from '../../assets/images/icons/chevron/down-chevron-3.svg';
import { ReactComponent as USAIcon } from '../../assets/images/icons/countries/usa.svg';
import { ReactComponent as CanadaIcon } from '../../assets/images/icons/countries/canada.svg';
import { ReactComponent as GlobalIcon } from '../../assets/images/icons/countries/global.svg';

const CountrySelect = ({ options, selectedValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const selectedCountry = options.find(
    (country) => country.value === selectedValue,
  );

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOptionClick = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const renderCountryIcon = (icon) => {
    switch (icon) {
      case 'us':
        return <USAIcon className="text-[17px] w-[17px] " />;
      case 'canada':
        return <CanadaIcon className="text-[17px] w-[17px] " />;
      case 'global':
        return <GlobalIcon className="text-[17px] w-[17px] scale-[1.4] " />;
      default:
        return <span className="text-[17px] w-[17px]">{icon}</span>;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="country-select" ref={dropdownRef}>
      <div
        className="selected-option flex items-center justify-between  max-[380px]:pl-0 pl-2 max-[380px]:pr-2 pr-3 cursor-pointer gap-3"
        onClick={toggleDropdown}
      >
        {renderCountryIcon(selectedCountry.icon)}
        <DownChevron
          className={`transition-transform duration-300 ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </div>
      {isOpen && (
        <div className="options-list">
          {options.map((country) => (
            <div
              key={country.value}
              className="option flex items-center justify-start gap-3 p-1  cursor-pointer"
              onClick={() => handleOptionClick(country.value)}
            >
              {renderCountryIcon(country.icon)}
              <span className="text-[16px] w-[100px]">{country.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountrySelect;
