import React, { useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate, Redirect } from '@reach/router';
import cx from 'classnames';

import withUserContext from 'behaviors/withUserContext';
import LoginModal from 'components/modals/LoginModal/LoginModal';
import { CauzesSection, MobileTabs } from './components';

import useDonationLanding from 'hooks/useDonationLanding';
import GiveStartCauze from 'components/Asides/GiveStartCauze';
import GlobalFeed from 'components/Feed/GlobalFeed';
import FollowingFeed from 'components/Feed/FollowingFeed';
import useDashboardStore from 'stores/DashboardStore';
import useMobile from 'hooks/useMobile';

const DonationLandingView = ({
  donationLandingStore,
  uiStore,
  authStore,
  activeEntity,
  userProfileStore,
  profileStore,
  charityStore,
  companyStore,
}) => {
  const { updateProps, showLoadingScreen } = useDashboardStore();
  const { isTablet } = useMobile();

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [events, setEvents] = useState([]);
  const [joinedEvents, setJoinedEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [joinedEventsLoading, setJoinedEventsLoading] = useState(true);

  useEffect(() => {
    if (!activeEntity) {
      setEvents([]);
      setJoinedEvents([]);
      setEventsLoading(false);
      setJoinedEventsLoading(false);
      return;
    }

    setEventsLoading(true);
    setJoinedEventsLoading(true);

    const isCharity = activeEntity.entityType === 'CHARITY';
    const isCompany = activeEntity.entityType === 'COMPANY';
    const isUser =
      activeEntity.entityType === 'USER' ||
      activeEntity.entityType === 'INFLUENCER';
    const entityId = activeEntity.id;

    if (isCharity) {
      charityStore
        .getEvents({ id: entityId, pinnedOnly: false, includeExternal: true })
        .then((_events) => setEvents(_events.slice(0, 5)))
        .finally(() => setEventsLoading(false));
      charityStore
        .getJoinedEvents({ id: entityId })
        .then((_events) => setJoinedEvents(_events.slice(0, 5)))
        .finally(() => {
          setJoinedEventsLoading(false);
        });
    } else if (isCompany) {
      companyStore
        .getCompanyEvents({ id: entityId })
        .then((_events) => setEvents(_events.slice(0, 5)))
        .finally(() => {
          setEventsLoading(false);
        });
      companyStore
        .getCompanyJoinedEvents({ id: entityId })
        .then((_events) => setJoinedEvents(_events.slice(0, 5)))
        .finally(() => {
          setJoinedEventsLoading(false);
        });
    } else if (isUser) {
      profileStore
        .getCauzes(true)
        .then((_events) => setEvents(_events.slice(0, 5)))
        .finally(() => {
          setEventsLoading(false);
        });
      userProfileStore
        .getUserJoinedEvents({ id: entityId })
        .then((_events) => setJoinedEvents(_events.slice(0, 5)))
        .finally(() => setJoinedEventsLoading(false));
    }
  }, [activeEntity]);

  useDonationLanding();

  useEffect(() => {
    if (donationLandingStore.lastScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, donationLandingStore.lastScrollPosition);
      }, 1);
    }

    return () => donationLandingStore.updateScrollPosition();
  }, []);

  if (!authStore.isAuthenticated && donationLandingStore.giftRedeemed) {
    uiStore.showNotification({
      body: 'This gift has already been donated.',
      type: 'ERROR',
      onDismiss: () => {},
      autoDismiss: true,
    });
  }
  const isEmpty = useMemo(() => {
    return !events?.length && !joinedEvents?.length;
  }, [events, joinedEvents]);

  if (!authStore.isAuthenticated && donationLandingStore.giftRedeemed) {
    uiStore.showNotification({
      body: 'This gift has already been donated.',
      type: 'ERROR',
      onDismiss: () => {},
      autoDismiss: true,
    });
  }

  useEffect(() => {
    updateProps({
      size: 'half',
      title:
        isEmpty && !eventsLoading && !joinedEventsLoading ? 'My Dashboard' : '',
      asideChildren: authStore.isAuthenticated && (
        <div
          className={cx('xl:block', {
            hidden: tabIndex === 1,
          })}
        >
          <GiveStartCauze />
        </div>
      ),
    });
  }, [
    authStore.isAuthenticated,
    isEmpty,
    tabIndex,
    isTablet,
    eventsLoading,
    joinedEventsLoading,
  ]);

  useEffect(() => {
    showLoadingScreen('', {});
  }, []);

  if (!authStore.isAuthenticated) {
    return <Redirect to="/discover" noThrow />;
  }

  return (
    <div className="donation-landing">
      <LoginModal
        isOpen={showLoginModal}
        onToggleClose={() => setShowLoginModal(false)}
      />
      <div>
        {isEmpty && !joinedEventsLoading && !eventsLoading && (
          <div className="mx-4 xl:mx-0 p-10 flex flex-col items-center text-center gap-y-6 border border-solid border-lightgray-f1f rounded-[10px] h-auto mb-10">
            <h2 className="font-agenda-bold text-[28px] leading-none">
              Join others and track your influence here
            </h2>
            <p className="font-agenda">
              Giving together is how we all make a difference. When inspired by
              others, join them and pass it on.
            </p>
            <button
              className="cz-btn-black uppercase h-12"
              onClick={() => navigate('/discover')}
            >
              discover cauzes
            </button>
          </div>
        )}
        <div
          className={cx('xl:hidden', {
            '!mb-4': tabIndex === 1,
            '!mb-8': tabIndex === 0,
            block: authStore.isAuthenticated,
            hidden: !authStore.isAuthenticated || isEmpty,
          })}
        >
          <MobileTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </div>
        <div
          className={cx('xl:flex flex-col gap-y-10 mb-0 xl:mb-10', {
            hidden: tabIndex === 1,
            'hidden xl:hidden': !authStore.isAuthenticated || isEmpty,
            'px-4': isTablet,
          })}
        >
          <CauzesSection
            title="Your Cauzes"
            events={events}
            loading={eventsLoading}
            viewAllHref="/profile/cauzes"
          />
          <CauzesSection
            title="Cauzes You've Joined"
            events={joinedEvents}
            loading={joinedEventsLoading}
            viewAllHref="/profile/cauzes"
          />
        </div>
        <div
          className={cx('xl:block', {
            hidden: isEmpty || (tabIndex === 0 && authStore.isAuthenticated),
          })}
        >
          {!authStore.isAuthenticated ? (
            <GlobalFeed
              activeEntity={activeEntity}
              setShowLoginModal={setShowLoginModal}
              title="Global Feed"
            />
          ) : (
            <FollowingFeed
              activeEntity={activeEntity}
              setShowLoginModal={setShowLoginModal}
              title="People You Follow"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'donationLandingStore',
    'searchStore',
    'uiStore',
    'authStore',
    'profileStore',
    'userProfileStore',
    'charityStore',
    'companyStore',
  )(observer(DonationLandingView)),
);
